import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/Layout';
import { Login } from '../components/Private/Login';
import PrivateRoute from '../components/Private/PrivateRoute';
import { Content } from '../components/Private/Content';
import Metas from "../components/Metas";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const Prive = ({pageContext}) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    const isBrowser = typeof window !== "undefined"

    return (
        <Layout>
            <Metas title="Espace privé"/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    {isBrowser && (
                        <Router>
                            <Login path="/prive/connexion"/>
                            <PrivateRoute path="/prive" component={Content}/>
                        </Router>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default Prive