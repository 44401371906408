import React, { useEffect, useState } from 'react'
import { getToken } from '../../services/auth'
import { appFetch, GET } from '../../Utils/appFetch'
import Title from "../Title";
import HtmlParser from "../HtmlParser";

export const Content = () => {
    const [data, setData] = useState({ value: [], loading: true })
    useEffect(() => {
        (async () => {
            let token = await getToken()
            if (token !== null) {
                appFetch(
                    GET,
                    'prive?_format=json',
                    null,
                    token,
                    (result) => {
                        setData({ value: result, loading: false })
                    },
                    () => (setData({ value: [], loading: false }))
                )
            }
        })()
    }, [])
    return (
        <>
            <Title title={"Espace privé"} />
            <div>
                {data.loading ? <span>...chargement</span> : data.value.map((value, index) => {
                        return (
                            <div key={index} className="content">
                                <h2 className="title is-2">{value.title[0].value}</h2>
                                <div className="content">
                                    <HtmlParser html={data.block.body.value}/>
                                </div>
                            </div>)
                    }
                )}
            </div>
        </>
    )
}