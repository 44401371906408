import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { setAuthData, isLoggedIn } from '../../services/auth'
import { appFetch, POST } from '../../Utils/appFetch'
import Title from "../Title";

export const Login = () => {

    const [username, setUsername] = useState(
        { value: null, error: false, helperText: null })
    const [password, setPassword] = useState(
        { value: null, error: false, helperText: null })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()
        if (!username.value) {
            setUsername({ value: null, error: true, helperText: 'Ce champs est requis' })
        }

        if (!password.value) {
            setPassword({ value: null, error: true, helperText: 'Ce champs est requis' })
        }

        if (!username.value || !password.value) {
            setError('Merci de renseigner le nom d\'utilisateur et le mot de passe')
            window.flash('Merci de renseigner le nom d\'utilisateur et le mot de passe', 'danger')
            return false
        }

        setLoading(true)
        let formData = new FormData()
        formData.append('grant_type', 'password')
        formData.append('client_id', process.env.CLIENT_ID)
        formData.append('client_secret', process.env.CLIENT_SECRET)
        formData.append('username', username.value)
        formData.append('password', password.value)
        appFetch(
            POST,
            'oauth/token',
            formData,
            null,
            result => {
                if (result.access_token) {
                    setAuthData(result.access_token, result.refresh_token)
                    navigate('/prive')
                } else {
                    window.flash('Une erreur est survenue', 'danger')
                    setError('Une erreur est survenue')
                }
                setLoading(false)
            },
            (error) => {
                window.flash('Identifiants invalides', 'danger')
                setError('Identifiants invalides')
                setLoading(false)
            }
        )
    }
    if (isLoggedIn()) {
        navigate(`/prive`)
    }
    {
        return (
            <>
                <Title title={"Espace privé"} subtitle={"Identifiez-vous"} />
                <div className="columns">
                    <div className="column is-4-tablet is-4-desktop is-4-widescreen">
                        <form
                            method="post"
                            onSubmit={handleSubmit}
                        >
                            <div className="field">
                                <label className="label">
                                    Email
                                    <input
                                        type="text"
                                        name="username"
                                        onChange={(e) => {
                                            setUsername(
                                                { value: e.target.value, error: false, helperText: null })
                                            setError(false)
                                        }}
                                        className="input"
                                    />
                                    {username.helperText && (
                                        <span className="help is-danger">{username.helperText}</span>)}
                                </label>
                            </div>
                            <div className="field">
                                <label className=" label">
                                    Mot de passe
                                    <input
                                        type="password"
                                        name=" password"
                                        onChange={(e) => {
                                            setPassword(
                                                { value: e.target.value, error: false, helperText: null })
                                            setError(false)
                                        }}
                                        className=" input"
                                    />
                                    {password.helperText && (
                                        <span className="help is-danger">{password.helperText}</span>)}
                                </label>
                            </div>
                            <div className="field">
                                <input className="button is-primary is-rounded" type="submit" value="Connexion" disabled={loading}/>
                            </div>
                            {/*{error && (<span className="help is-danger">{error}</span>)}*/}
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
